<template>
  <div>
    <h2>Profil Saya</h2>
    <a-card>
      <div class="profile-container mb-5">
        <div class="profile">
          <div class="avatar mb-3">
            <img
              width="80"
              :src="
                data.profilePictureSmall
                  ? `${backendUrl}${data.profilePictureSmall}`
                  : '/img/female-profile.png'
              "
              alt="User's Profile Picture"
            />
          </div>
          <a
            class="btn"
            shape="round"
            @click="toggleShowUploadAvatar"
          ><i class="fa fa-upload"></i> Unggah </a>
          <upload-avatar
            field="avatar"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-if="showUploadAvatar"
            :headers="headers"
            :width="300"
            :height="300"
            :url="`${backendUrl}api/user/upload-avatar`"
            lang-type="en"
            img-format="png"
            :with-credentials="true"
          ></upload-avatar>
        </div>
      </div>
      <a-form layout="vertical">
        <a-form-item label="Nama Depan">
          <a-input placeholder="Masukkan nama depan" v-model:value="data.firstName" />
        </a-form-item>
        <a-form-item label="Nama Belakang">
          <a-input placeholder="Masukkan nama belakang" v-model:value="data.lastName" />
        </a-form-item>
        <a-form-item label="Jenis Kelamin">
          <a-radio-group button-style="solid" v-model:value="data.gender" @change="(e) => handleChangeGender(e.target.value)">
            <a-radio-button value="Laki-Laki">Laki-Laki</a-radio-button>
            <a-radio-button value="Perempuan">Perempuan</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="Nomor Telepon / HP">
          <a-input placeholder="Masukkan nomor telepon / hp" v-model:value="data.phoneNumber" />
        </a-form-item>
        <a-form-item label="Deskripsi">
          <a-textarea placeholder="Masukkan tentang anda" allow-clear v-model:value="data.description" />
        </a-form-item>
        <a-form-item label="Email">
          <a-input placeholder="Masukkan email" v-model:value="data.email" />
        </a-form-item>
        <a-form-item label="Password">
          <a-input-password allow-clear placeholder="Masukkan password baru, jika kolom ini tidak di isi password tidak akan berubah" v-model:value="data.newPassword" />
        </a-form-item>
        <a-form-item >
          <a-button type="primary" @click="changeAdminProfile"> Ubah Profil </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRaw, createVNode } from 'vue';
import { useStore } from 'vuex';
import uploadAvatar from 'vue-image-crop-upload';
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    'upload-avatar': uploadAvatar,
  },
  setup() {
    const store = useStore()
    let data = ref({})
    let showUploadAvatar = ref(false)
    let headers = ref({
      smail: '*_~',
    })
    let imgDataUrl = reactive('')
    let backendUrl = ref(process.env.VUE_APP_BACKEND_URL)

    onMounted(async () => {
      fetchMyData()
    })

    const fetchMyData = async () => {
      const rawData = await store.dispatch('admin/FETCH_ADMIN_BY_ID')
      // console.log('fetchMyData => ', rawData)
      rawData.newPassword = null
      data.value = rawData
    }

    const toggleShowUploadAvatar = () => {
      showUploadAvatar.value = !showUploadAvatar.value
    }

    const cropSuccess = (imgDataUrl, field) => {
      console.log('-------- crop success --------');
      imgDataUrl = imgDataUrl;
    }

    const cropUploadSuccess = (jsonData, field) => {
      console.log('-------- upload success --------')
      console.log(jsonData)
      console.log('field: ' + field)
      toggleShowUploadAvatar()
      fetchMyData()
    }

    const cropUploadFail = (status, field) => {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
      toggleShowUploadAvatar()
      fetchMyData()
    }

    const handleChangeGender = (genderValue) => {
      data.value.gender = genderValue
    }

    const changeAdminProfile = async () => {
      Modal.confirm({
        title: `Apa anda yakin ingin mengubah profil?`,
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          try {
            const newAdminProfile = data.value
            const updateProfile = await store.dispatch('admin/UPDATE_ADMIN_PROFILE', { newAdminProfile })
            console.log('updateProfile', updateProfile)
            if (updateProfile.isSuccess === true) {
              notification.success({
                message: 'Sukses',
                description: 'Berhasil Mengubah Profil',
              })
            } else {
              notification.error({
                message: 'Error!',
                description: 'Gagal Mengubah Profil!',
              })
            }
            fetchMyData()
          } catch (err) {
            console.log(err)
          }
        },
        onCancel() {},
      })
    }

    return {
      data,
      backendUrl,
      showUploadAvatar,
      headers,
      toggleShowUploadAvatar,
      cropSuccess,
      cropUploadSuccess,
      cropUploadFail,
      imgDataUrl,
      handleChangeGender,
      changeAdminProfile,
    }
  },
}
</script>

<style lang="scss">
.profile-container {
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.avatar {
  width: rem(130);
  height: rem(130);
  border-radius: 50%;
  overflow: hidden;
  background-color: gray;
  text-align: center;
  vertical-align: middle;
}
</style>